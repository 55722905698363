<template>
	<div>
		<a-spin :tip="''" size="large" :spinning="false">
			<div class="card">
				<div class="card-body">
					<div class="row"></div>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
//

export default {
	name: 'QuotesDashboardView',
	data() {
		return {}
	},
}
</script>
